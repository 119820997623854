<template>
  <vs-col>
    <WizardErrors />
    <FormWizard
      ref="catalogCreatorWizard"
      :title="null"
      :subtitle="null"
      :next-button-text="$t('catalogWizard.next')"
      :back-button-text="$t('catalogWizard.previous')"
      :finish-button-text="$t('catalogWizard.save')"
      :start-index="0"
      layout="horizontal"
      shape="circle"
      step-size="sm"
      color="#39BA9B"
      error-color="red"
      :hide-buttons="hideButtons"
    >
      <tab-content
        :title="$t('catalogWizard.tabsTitle.setMapping')"
        icon="feather icon-map"
        :before-change="validateMapDataChange"
      >
        <b-overlay :show="isLoading" rounded spinner-variant="primary" variants="transparent" z-index="400">
          <validation-observer ref="setMappingValidationObserver">
            <MapData />
          </validation-observer>
        </b-overlay>
      </tab-content>
      <tab-content
        :title="$t('catalogWizard.tabsTitle.catalogPreview')"
        icon="feather icon-search"
        :before-change="goToCatalogInstancesList"
      >
        <b-overlay :show="isLoading" spinner-variant="primary" variants="transparent">
          <CatalogImportSummary />
        </b-overlay>
      </tab-content>
    </FormWizard>
  </vs-col>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import CatalogImportSummary from '@/views/components/catalog/CatalogImportSummary.vue'
import MapData from '@/views/components/catalog/MapData.vue'
import WizardErrors from '@/views/components/catalog/WizardErrors.vue'

import moduleSearchCatalogEditMapping from '@/store/searchCatalogMapping/moduleSearchCatalogEditMapping'

export default {
  components: {
    WizardErrors,
    MapData,
    CatalogImportSummary,
    FormWizard,
    TabContent,
  },
  computed: {
    isLoading() {
      return moduleSearchCatalogEditMapping.state.isLoading
    },
    hideButtons() {
      return moduleSearchCatalogEditMapping.state.hideButtons
    },
  },
  mounted() {
    moduleSearchCatalogEditMapping.dispatch('loadMappingData', this.$route.params.catalogId)
  },
  methods: {
    goToCatalogInstancesList() {
      this.$router.push({ name: 'catalogInstances' })
    },
    async validateMapDataChange() {
      return this.$refs.setMappingValidationObserver.validate()
        .then(validationResult => {
          if (validationResult) {
            return moduleSearchCatalogEditMapping.dispatch('open3rdStep', this.$route.params.catalogId)
          }
          return false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
