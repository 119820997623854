import Vue from 'vue'
import Vuex from 'vuex'
import CatalogApiRequest from '@/store/catalog/catalogApiRequest'
import additionalFields from '@/store/searchCatalogMapping/additionalFields'
import requiredFields from '@/store/searchCatalogMapping/requiredFields'
import moduleSearchCatalogMapping from '@/store/searchCatalogMapping/moduleSearchCatalogMapping'
import Notifications from '@/store/notifications/notifications'
import { AxiosResponse } from 'axios'
import moduleImportedCatalog from '@/store/importedCatalog/moduleImportedCatalog'
import moduleCatalog from '../catalog/moduleCatalog'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // wizard status
    isLoading: false,

    // wizard tab index
    currentTabIndex: 1,
    hideButtons: false,
  },
  mutations: {
    startLoading(state: any) {
      state.isLoading = true
      state.hideButtons = true
    },
    endLoading(state: any) {
      state.isLoading = false
      state.hideButtons = false
    },
    hideButtons(state: any) {
      state.hideButtons = true
    },
    showButtons(state: any) {
      state.hideButtons = false
    },
  },
  actions: {
    loadMappingData(context: any, catalogId: string) {
      moduleSearchCatalogMapping.commit('setFoundPaths', [])
      CatalogApiRequest.getCatalogFoundPaths(catalogId).then(responseAnalyzer => {
        moduleSearchCatalogMapping.commit('setFoundPaths', responseAnalyzer.data)
        additionalFields.commit('clearAdditionalField')
        CatalogApiRequest.getCatalogUrl(catalogId).then(responseUrl => {
          moduleCatalog.dispatch('setCatalogLink', responseUrl.data.url)
          moduleSearchCatalogMapping.dispatch('getPreviewData', responseUrl.data.url, { root: true })
        })
        CatalogApiRequest.getCatalogMapping(catalogId).then(responseMapping => {
          responseMapping.data.forEach((element: any) => {
            if (element.toPath == null) { return }
            const fieldData = {
              description: '',
              qonId: element.toPath,
              catalogField: element.fromPath,
              dataType: element.castType,
              separator: element.separateBy,
              isDeletable: element.isDeletable,
            }
            if (['id', 'title', 'categories', 'link', 'image_link', 'availability', 'price'].indexOf(element.toPath) >= 0) {
              requiredFields.commit('setRequiredFields', fieldData)
            } else {
              additionalFields.commit('addAdditionalFieldData', fieldData)
            }
          })
          context.commit('showButtons')
        })
      })
    },
    async saveMappingSettings(context, catalogId: string) {
      const sendRequiredFields = requiredFields.state.requiredFields.map((field: any) => ({
        path: field.qonId,
        fieldLabel: field.catalogField,
        fieldType: field.dataType,
        separator: field.separator,
      }))

      const sendAdditionalFields = additionalFields.state.additionalFields.map((field: any) => ({
        path: field.qonId,
        fieldLabel: field.catalogField,
        fieldType: field.dataType,
        separator: field.separator,
      }))

      const payload = {
        mapping: [
          ...sendRequiredFields,
          ...sendAdditionalFields,
        ],
        catalogId,
      }
      return CatalogApiRequest.storeCatalogMapping(payload)
    },

    open3rdStep(context: any, catalogId: string): Promise<boolean> {
      context.commit('startLoading')
      Notifications.commit('clearErrors', {}, { root: true })
      moduleImportedCatalog.dispatch('resetProductList', {}, { root: true })
      return context.dispatch('saveMappingSettings', catalogId, { root: true })
        .then(() => {
          context.commit('endLoading')
          context.commit('hideButtons')
          const interval = setInterval(() => {
            moduleImportedCatalog.dispatch('isCatalogProdFinished', catalogId, { root: true })
              .then((result: AxiosResponse) => {
                if (result.status === 200) {
                  clearInterval(interval)
                  const productInterval = setInterval(() => {
                    moduleImportedCatalog.dispatch('getProdProductsList', catalogId, { root: true })
                      .then((importProductResult: AxiosResponse) => {
                        if (importProductResult.status === 200) {
                          context.commit('showButtons')
                          clearInterval(productInterval)
                        }
                      })
                  }, 5000)
                }
                return true
              })
              // .catch((error: any) => {
              //   console.dir(error)
              //   context.commit('showButtons')
              //   clearInterval(interval)
              // })
          }, 2000)
          return true
        })
        .catch((err: any) => {
          if (err.name === 'FIELDS_INVALID_ERROR') {
            Notifications.commit('addError', 'catalogMapping.error.fieldsInvalid', { root: true })
          } else if (err.isAxiosError) {
            Notifications.commit('addError', 'catalogMapping.error.storeRequestFailed', { root: true })
          } else {
            Notifications.commit('addError', 'catalogMapping.error.storeRequestUnknown', { root: true })
          }
          context.commit('endLoading')
          return false
        })
    },
  },
})
